import {
  QuizComment,
  QuizQuestion,
  QuizQuestionFile,
} from 'apis/entities/quiz.entity'
import tickSvg from 'images/tick.svg'
import crossSvg from 'images/cross.svg'
import minusSvg from 'images/minus.svg'
import plusSvg from 'images/plus.svg'
import {
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { Switch } from './Switch'
import React from 'react'
import { getExtensionIcon, isAudio, isImage } from 'utils/FileUtil'
import { QuestionData, useData } from 'providers/DataProvider'

type QuestionViewProps = {
  index: number
  quizVersion: number
  question: QuizQuestion
  isReviewed: boolean
  previousComment: QuizComment | null
  cacheData: QuestionData | null
  onChanged: (animated: boolean) => void
  onClickTopicLink: (url: string) => void
}

export interface QuestionViewRef {
  question: QuizQuestion
  isExpanded: boolean
  approved: boolean | null
  comment: string
  onToggleExpand: () => void
  onMinimize: () => void
  toggleHighlightComment: (highlight: boolean) => void
  toggleHighlightQuestion: (highlight: boolean) => void
  scrollIntoView: () => void
}

const QuestionView = forwardRef<QuestionViewRef, QuestionViewProps>(
  (
    {
      index,
      quizVersion,
      question,
      isReviewed,
      previousComment,
      cacheData,
      onChanged,
      onClickTopicLink,
    }: QuestionViewProps,
    ref,
  ) => {
    const refDiv = useRef<HTMLDivElement>(null)
    const isEdited = () => {
      if (question.previous == null) {
        return false
      }

      // double check if the question is edited
      const edited =
        question.text !== question.previous?.text ||
        JSON.stringify(question.answers) !==
          JSON.stringify(question.previous?.answers) ||
        question.learningOutcome !== question.previous?.learningOutcome

      return edited
    }

    const isApproved = () => {
      if (cacheData) {
        return cacheData.approved
      }
      // console.log('isApproved', previousComment)
      return previousComment?.approved === true
        ? true
        : isReviewed
          ? false
          : null
    }

    const getComment = () => {
      if (cacheData) {
        return cacheData.comment || ''
      }
      return isReviewed ? previousComment?.text || '' : ''
    }

    const getShowComments = () => {
      if (cacheData) {
        return cacheData.approved === false
      }
      return isReviewed
    }

    const getIsExpanded = () => {
      if (cacheData) {
        return cacheData.approved === false
      }
      const value =
        isEdited() ||
        quizVersion === 1 ||
        isApproved() === false ||
        isApproved() === null
      // console.log(`isEdited: ${isEdited()}, quizVersion: ${quizVersion}, isApproved: ${isApproved()}`)
      return value
    }

    const [isExpanded, setIsExpanded] = useState<boolean>(getIsExpanded())
    const refComment = createRef<HTMLTextAreaElement>()
    const [approved, setApproved] = useState<boolean | null>(isApproved())
    const [commentText, setCommentText] = useState<string>(getComment())

    const [questionText, setQuestionText] = useState(question.text || '')
    const [learningOutcome, setLearningOutcome] = useState(
      question.learningOutcome || '',
    )
    const [answers, setAnswers] = useState(question.answers || [])
    const [isCurrentVersion, setIsCurrentVersion] = useState<boolean>(true)
    const [showComments, setShowComments] = useState<boolean>(getShowComments())
    const [highlightComment, setHighlightComment] = useState<boolean>(false)
    const [highlightQuestion, setHighlightQuestion] = useState<boolean>(false)
    const { setDataByQuestionId } = useData()

    const hasPreviousVersion = () => {
      // console.log(question)
      return (
        question.previous?.text != null ||
        question.previous?.answers != null ||
        question.previous?.learningOutcome != null
      )
    }

    const onToggleExpand = () => {
      setIsExpanded(!isExpanded)
    }

    const onMinimize = () => {
      setIsExpanded(false)
    }

    const toggleHighlightComment = (highlight: boolean) => {
      setHighlightComment(highlight)
    }

    const toggleHighlightQuestion = (highlight: boolean) => {
      setHighlightQuestion(highlight)
    }

    const isReadOnly = isReviewed || !isCurrentVersion

    const onClickReject = () => {
      if (isReadOnly) return
      setApproved(false)
      setShowComments(true)
      // save cache
      setDataByQuestionId(question.id, {
        id: question.id,
        comment: commentText,
        approved: false,
      })
      onChanged(false)
    }

    const onClilckApprove = () => {
      if (isReadOnly) return
      setApproved(true)
      setShowComments(false)
      // save cache
      setDataByQuestionId(question.id, {
        id: question.id,
        comment: commentText,
        approved: true,
      })
      onChanged(true)
    }

    const onChangeComment = () => {
      // auto save
      let comment = refComment.current?.value || ''
      comment = comment.trim()
      setCommentText(comment)
      // save cache
      setDataByQuestionId(question.id, {
        id: question.id,
        comment: comment,
        approved: false,
      })
      onChanged(false)
    }

    const onChangeSwitch = (checked: boolean) => {
      setIsCurrentVersion(checked)
      if (checked) {
        // use current version
        setQuestionText(question.text || '')
        setLearningOutcome(question.learningOutcome || '')
        setAnswers(question.answers || [])
      } else {
        // use previous version
        setQuestionText(question.previous?.text || '')
        setLearningOutcome(question.previous?.learningOutcome || '')
        setAnswers(question.previous?.answers || [])
      }
    }

    const scrollIntoView = () => {
      const element = refDiv.current
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const renderMedia = (file: QuizQuestionFile, index: number) => {
      // switch case for images, videos and others
      // TODO: make use of file.type
      const type = file.url.split('.').pop()
      // check if it is image
      if (type && isImage(type)) {
        return (
          <a
            key={index}
            href={file.url}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row justify-center items-center"
          >
            <img src={file.url} alt="media" style={{ maxHeight: '30vh' }} />
          </a>
        )
      } else if (type === 'mp4') {
        return (
          <div
            key={index}
            className="flex flex-row justify-center items-center"
          >
            <video width="320" height="240" controls>
              <source src={file.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      } else if (type && isAudio(type)) {
        // audio player
        return (
          <div
            key={index}
            className="flex flex-row justify-center items-center"
          >
            <audio controls>
              <source src={file.url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )
      } else {
        // other types, display link
        return (
          <a key={index} href={file.url} target="_blank" rel="noreferrer">
            <div className="flex flex-row gap-2 items-center justify-center">
              <img src={getExtensionIcon(file.url)} alt={file.url} />
              {file.name}
            </div>
          </a>
        )
      }
    }

    useImperativeHandle(ref, () => ({
      question,
      isExpanded,
      approved,
      comment: commentText,
      onToggleExpand,
      onMinimize,
      toggleHighlightComment,
      toggleHighlightQuestion,
      scrollIntoView,
    }))

    return (
      <div
        ref={refDiv}
        className={`flex flex-col pt-[24px] pl-[24px] pr-[24px] w-full gap-[12px] ${
          highlightQuestion ? 'question-highlight ' : ''
        }`}
      >
        <div className="flex flex-row gap-2">
          <div className="flex flex-row grow gap-1 question">
            <span>{index + 1}.</span>
            <span>
              {questionText.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </span>
          </div>
          {hasPreviousVersion() && !isReviewed && isEdited() && (
            <div className="flex mr-6">
              <Switch checked={true} onChange={onChangeSwitch} />
            </div>
          )}
          <button
            className="flex-end shrink-0 w-[26px]"
            onClick={() => onToggleExpand()}
          >
            <img src={isExpanded ? minusSvg : plusSvg} alt="min-max" />
          </button>
        </div>
        {isExpanded && (
          <>
            <div className="flex flex-row learning-outcome">
              Learning outcome:{' '}
              {learningOutcome.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
            {question.archTopic && (
              <div className="flex flex-row gap-1 topic-link">
                <span>Topic link</span>
                {question.archTopic.link ? (
                  <span
                    className="topic-name underline cursor-pointer"
                    onClick={() => onClickTopicLink(question.archTopic.link!)}
                  >
                    {question.archTopic.name}
                  </span>
                ) : (
                  <span className="topic-name">{question.archTopic.name}</span>
                )}
              </div>
            )}
          </>
        )}
        <div className="separator" />
        {isExpanded && (
          <>
            <div className="flex flex-col gap-[12px]">
              {answers?.map((answer, index) => (
                <div key={index} className="flex flex-row gap-[12px]">
                  <img
                    className="shrink-0 w-[26px]"
                    src={index === 0 ? tickSvg : crossSvg}
                    alt="correct-incorrect"
                  />
                  <span>
                    {answer.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center justify-center gap-4">
              {question.files.map((file, index) => renderMedia(file, index))}
            </div>
            <div className="self-end">
              <div className="flex flex-row gap-[12px]">
                <button
                  className={
                    approved === null || approved === true
                      ? isReadOnly
                        ? StyleUtil.buttonSecondaryReadOnly
                        : StyleUtil.buttonSecondary
                      : StyleUtil.buttonRejected
                  }
                  onClick={() => onClickReject()}
                >
                  {approved === null || approved === true
                    ? 'Reject'
                    : 'Rejected'}
                </button>
                <button
                  className={
                    approved === true
                      ? StyleUtil.buttonApproved
                      : isReadOnly
                        ? StyleUtil.buttonSecondaryReadOnly
                        : StyleUtil.buttonSecondary
                  }
                  onClick={() => onClilckApprove()}
                >
                  {approved ? 'Approved' : 'Approve'}
                </button>
              </div>
            </div>
            <div>
              {isCurrentVersion && showComments && !isReadOnly && (
                <textarea
                  ref={refComment}
                  className={`comment-input ${
                    highlightComment ? 'comment-input-highlight' : ''
                  }`}
                  placeholder="Type comments here"
                  onChange={() => onChangeComment()}
                  disabled={isReadOnly}
                  defaultValue={commentText}
                />
              )}
              {isCurrentVersion &&
                isReadOnly &&
                commentText &&
                commentText.length > 0 && (
                  <div className="quiz-comment-container">
                    <p className="quiz-text-comment">{commentText}</p>
                  </div>
                )}
              {!isCurrentVersion &&
                previousComment &&
                previousComment.text.length > 0 && (
                  <div className="quiz-comment-container">
                    <p className="quiz-text-comment">{previousComment.text}</p>
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    )
  },
)

export default QuestionView
