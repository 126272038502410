/**
 * StyleUtil className
 */
export const StyleUtil = {
  buttonPrimary:
    'mentem-button text-white bg-primary border-primary btn-sm btn rounded-3xl hover:text-[#3C415D] hover:bg-white hover:border-[#D5D5DB] shadow-lg',
  buttonSecondary:
    'mentem-button text-[#3C415D] bg-white border-[#D5D5DB] btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary shadow-lg',
  buttonPrimaryReadOnly:
    'mentem-button text-white bg-primary border-primary btn-sm btn rounded-3xl shadow-lg cursor-default',
  buttonSecondaryReadOnly:
    'mentem-button text-[#3C415D] bg-white border-[#D5D5DB] btn-sm btn rounded-3xl shadow-lg cursor-default',
  buttonRejected:
    'mentem-button text-white bg-[#df1642] border-[#df1642] btn-sm btn rounded-3xl hover:text-white hover:bg-[#df1642] hover:border-[#df1642] shadow-lg cursor-default',
  buttonApproved:
    'mentem-button text-white bg-[#08918d] border-[#08918d] btn-sm btn rounded-3xl hover:text-white hover:bg-[#08918d] hover:border-[#08918d] shadow-lg cursor-default',
}
