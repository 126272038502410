import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react'

type Props = {
  children: ReactNode
}

interface AuthValue {
  isLogged: boolean
  reviewerId: string
  setReviewerId: (id: string) => void
}

export const AuthContext = createContext<AuthValue | null>(null)

export function useAuth(): AuthValue {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return state
}

const AuthProvider = ({ children }: Props) => {
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [reviewerId, setReviewerId] = useState<string>('')

  useEffect(() => {
    if (reviewerId) {
      setIsLogged(true)
    }
  }, [reviewerId])

  const providerValue = {
    isLogged,
    reviewerId,
    setReviewerId,
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
