import { Quiz } from 'apis/entities/quiz.entity'
import React, { createContext, useCallback, useContext, useEffect } from 'react'

export interface QuestionData {
  id: string
  comment: string | null
  approved: boolean | null
}

interface QuizData {
  id: string
  version: number
  questions: Array<QuestionData>
}

interface DataProviderProps {
  children: React.ReactNode
}

interface DataContextProps {
  setQuiz: (quiz: Quiz) => void
  getDataByQuestionId: (questionId: string) => QuestionData | null
  setDataByQuestionId: (questionId: string, data: QuestionData) => void
  clearData: () => void
  saveData: () => void
  getData: () => string | null
  setData: (data: QuizData) => void
}

const DataContext = createContext<DataContextProps | undefined>(undefined)

export const STORAGE_KEY_PREFIX = 'lx-reviewer-quiz'

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const refData = React.useRef<QuizData | null>(null)

  const getQuizFromCache = (quiz: Quiz) => {
    const key = getKey(quiz)
    if (!key) return null
    const storedData = localStorage.getItem(key)
    if (storedData) {
      const data = JSON.parse(storedData) as QuizData
      if (data.id === quiz.id && data.version === quiz.version) {
        return data
      }
    }
    return null
  }

  const setQuiz = (quiz: Quiz) => {
    const cachedData = getQuizFromCache(quiz)
    // console.log('Setting quiz', quiz, 'cachedData', cachedData)
    if (cachedData) {
      refData.current = cachedData
      return
    }
    // new quiz, reset data
    refData.current = {
      id: quiz.id,
      version: quiz.version,
      questions: quiz.questions.map((q) => ({
        id: q.id,
        comment: null,
        approved: null,
      })),
    }
  }

  const getKey = useCallback((quiz?: Quiz) => {
    if (quiz) {
      return `${STORAGE_KEY_PREFIX}-${quiz.id}-v${quiz.version}`
    }
    if (!refData.current) return null
    return `${STORAGE_KEY_PREFIX}-${refData.current.id}-v${refData.current.version}`
  }, [])

  const saveData = () => {
    const key = getKey()
    if (!key) return
    // console.log('Saving data', refData.current)
    localStorage.setItem(key, JSON.stringify(refData.current))
  }

  const setData = (data: QuizData) => {
    refData.current = data
    saveData()
  }

  const getData = () => {
    if (!refData.current) return null
    return JSON.stringify(refData.current)
  }

  const getDataByQuestionId = (questionId: string): QuestionData | null => {
    if (!refData.current) return null
    const question = refData.current.questions.find((q) => q.id === questionId)
    if (question?.approved === null) {
      return null
    }
    return question || null
  }

  const setDataByQuestionId = (questionId: string, newData: QuestionData) => {
    // console.log('Setting data', questionId, newData)
    if (!refData.current) {
      return
    }
    const newQuestions = refData.current.questions.map((q) => {
      if (q.id === questionId) {
        return newData
      }
      return q
    })
    refData.current = {
      ...refData.current,
      questions: newQuestions,
    }
    saveData()
  }

  const clearData = () => {
    const key = getKey()
    if (key) {
      localStorage.removeItem(key)
    }
  }

  useEffect(() => {
    const key = getKey()
    if (!key) return
    // Load data from local storage
    // console.log('Loading data from local storage')
    const storedData = localStorage.getItem(key)
    // console.log('Stored data', storedData)
    if (storedData) {
      refData.current = JSON.parse(storedData) as QuizData
      // console.log('Loaded data', refData.current)
    }
  }, [getKey])

  return (
    <DataContext.Provider
      value={{
        setQuiz,
        getDataByQuestionId,
        setDataByQuestionId,
        clearData,
        saveData,
        getData,
        setData,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const useData = (): DataContextProps => {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error('useData must be used within a DataProvider')
  }
  return context
}
