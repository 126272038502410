import pdfSvg from 'images/pdf.svg'
import pptSvg from 'images/powerpoint.svg'
import excelSvg from 'images/excel.svg'
import genericFileSvg from 'images/generic-file.svg'
import videoFileSvg from 'images/youtube.svg'
import soundFileSvg from 'images/sound-file.svg'
import wordDocSvg from 'images/word-doc.svg'

export function getFileSize(size: number): string {
  return size > 1024
    ? size > 1048576
      ? Math.round(size / 1048576) + 'mb'
      : Math.round(size / 1024) + 'kb'
    : size + 'b'
}
export function getExtension(filename: string): string {
  return filename.split('.').pop() || ''
}
export function getFilenameWithoutExtension(filename: string): string {
  const ext = getExtension(filename)
  if (ext === '') return filename
  return filename.slice(0, -ext.length - 1)
}
export function getExtensionIcon(filename: string): any {
  const ext = getExtension(filename)
  if (['doc', 'docx', 'docm'].includes(ext)) {
    return wordDocSvg
  } else if (['pdf'].includes(ext)) {
    return pdfSvg
  } else if (['ppt', 'pptx', 'pps', 'ppsx'].includes(ext)) {
    return pptSvg
  } else if (['xls', 'xlsx', 'csv'].includes(ext)) {
    return excelSvg
  } else if (['mp3', 'wav'].includes(ext)) {
    return soundFileSvg
  } else if (['mp4', 'mov'].includes(ext)) {
    return videoFileSvg
  } else {
    return genericFileSvg
  }
}

export function isImage(filename: string): boolean {
  const ext = getExtension(filename)
  return ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg'].includes(ext)
}

export function isVideo(filename: string): boolean {
  const ext = getExtension(filename)
  return ['mp4', 'mov'].includes(ext)
}

export function isAudio(filename: string): boolean {
  const ext = getExtension(filename)
  return ['mp3', 'wav'].includes(ext)
}
