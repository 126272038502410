import { toast } from 'react-toastify'

export const ToastUtil = {
  success: (message: string, style?: any) => {
    if (style) {
      toast.success(message, {
        icon: true,
        style: style,
      })
    } else {
      toast.success(message, {
        icon: true,
      })
    }
  },
  warning: (message: string) => {
    toast.warning(message)
  },
  error: (message: string) => {
    toast.error(message)
  },
}
