import React from 'react'
import './switch.css'

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
}

export function Switch({ checked, onChange }: Props) {
  const [isOn, setIsOn] = React.useState(checked)
  const onClick = () => {
    const checked = !isOn
    setIsOn(checked)
    onChange(checked)
  }

  const getStyle = () => {
    const style = isOn ? 'switch-on' : ''
    return `switch-container ${style}`
  }

  return (
    <div className="flex flex-row items-center justify-center gap-1">
      <div className={getStyle()} onClick={() => onClick()}>
        <div className="switch-thumb" />
      </div>
      <span className="switch-text">{isOn ? 'Current' : 'Previous'}</span>
    </div>
  )
}
