import { Navigate, Outlet } from 'react-router-dom'
import Path from './Path'
import { useApi } from '../providers/ApiProvider'
import { isLocal } from 'utils/EnvUtil'

function validateToken(token: string | null): boolean {
  return token !== null && token.length > 0
}

const ProtectedRoute = () => {
  const { setToken } = useApi()
  // Fetch JWT token from query string
  let currentToken = new URLSearchParams(window.location.search).get('token')
  // debug
  if (isLocal) currentToken = process.env.REACT_APP_TOKEN || ''

  const isValidToken = validateToken(currentToken)
  // Set JWT token in context
  setToken(isValidToken ? currentToken : null)
  return isValidToken ? <Outlet /> : <Navigate to={Path.error} replace />
}

export default ProtectedRoute
