import closeSvg from '../images/close.svg'

interface Props {
  url: string
  onClose: () => void
}

export default function ModalTopicLink({ url, onClose }: Props): JSX.Element {
  return (
    <div className="fixed inset-0 w-full h-full bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
      <div className="h-[90vh] w-[90vw] flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[8px] pb-[12px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        <iframe src={url} className="w-full h-full" title="topic"></iframe>
      </div>
    </div>
  )
}
