import { useContext, ReactNode, createContext, useRef } from 'react'
import { Quiz, QuizReviewLog, ReviewerComment } from 'apis/entities/quiz.entity'

type Props = {
  children: ReactNode
}

interface ApiValue {
  setToken(token: string | null): void
  getQuiz(version?: number): Promise<Quiz>
  getQuizReviewed(version?: number): Promise<Quiz>
  addComments(comments: ReviewerComment[]): Promise<any>
  getReviewLog(): Promise<QuizReviewLog>
  saveReviewCache(cache: string): Promise<any>
  getReviewCache(): Promise<string>
  exportQuiz(): Promise<any>
}

export const ApiContext = createContext<ApiValue | null>(null)

export function useApi(): ApiValue {
  const state = useContext(ApiContext)
  if (!state) {
    throw new Error('useApi must be used within ApiProvider')
  }
  return state
}

export function ApiProvider({ children }: Props) {
  const headers = { 'Content-Type': 'application/json' }
  const refToken = useRef<string | null>(null)
  const refHeaders = useRef<any>(headers)
  const documentHost = process.env.REACT_APP_DOCUMENT_API_URL

  const setToken = (token: string | null) => {
    refToken.current = token
    refHeaders.current = {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const getQuiz = async (version?: number): Promise<Quiz> => {
    const url = version
      ? `${documentHost}/reviews/quiz?version=${version}`
      : `${documentHost}/reviews/quiz`
    const res = await fetch(url, {
      method: 'GET',
      headers: refHeaders.current,
    })
    if (!res.ok) {
      throw new Error('Something went wrong')
    }
    return res.json()
  }

  const getQuizReviewed = async (version?: number): Promise<Quiz> => {
    const url = version
      ? `${documentHost}/reviews/quiz/reviewed?version=${version}`
      : `${documentHost}/reviews/quiz`
    const res = await fetch(url, {
      method: 'GET',
      headers: refHeaders.current,
    })
    if (!res.ok) {
      throw new Error('Something went wrong')
    }
    return res.json()
  }

  const addComments = async (comments: ReviewerComment[]): Promise<any> => {
    const res = await fetch(`${documentHost}/reviews/comments`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ comments: comments }),
    })
    if (!res.ok) {
      throw new Error('Something went wrong')
    }
    return res.json()
  }

  const getReviewLog = async (): Promise<QuizReviewLog> => {
    const res = await fetch(`${documentHost}/reviews/log`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    if (!res.ok) {
      throw new Error('Something went wrong')
    }
    return res.json()
  }

  const saveReviewCache = async (cache: string) => {
    const res = await fetch(`${documentHost}/reviews/cache`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ cache: cache }),
    })
    if (!res.ok) {
      throw new Error('Something went wrong')
    }
    return res.json()
  }

  const getReviewCache = async (): Promise<string> => {
    const res = await fetch(`${documentHost}/reviews/cache`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    if (!res.ok) {
      throw new Error('No cache found')
    }
    const result = await res.json()
    return result.cache
  }

  const exportQuiz = async (): Promise<any> => {
    const res = await fetch(`${documentHost}/reviews/export`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.blob()
  }

  const providerValue = {
    setToken,
    getQuiz,
    getQuizReviewed,
    addComments,
    getReviewLog,
    saveReviewCache,
    getReviewCache,
    exportQuiz,
  }

  return (
    <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>
  )
}
