import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ApiProvider } from 'providers/ApiProvider'
import AuthProvider from 'providers/AuthProvider'
import Path from 'routes/Path'
import HomePage from 'pages/HomePage'
import ErrorPage from 'pages/ErrorPage'
import ProtectedRoute from 'routes/ProtectedRoute'
import { ToastContainer } from 'react-toastify'
import { DataProvider } from 'providers/DataProvider'

export default function App() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <AuthProvider>
          <DataProvider>
            <ToastContainer />
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path={Path.home} element={<HomePage />} />
              </Route>
              <Route path={Path.error} element={<ErrorPage />} />
            </Routes>
          </DataProvider>
        </AuthProvider>
      </ApiProvider>
    </BrowserRouter>
  )
}
