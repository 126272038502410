/* eslint-disable react/jsx-no-target-blank */
export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-menu">
        <a href="https://www.mentem.co/" target="_blank">
          About Mentem
        </a>
        <a href="https://www.mentem.co/privacy" target="_blank">
          Privacy Policy
        </a>
        <a href="https://www.mentem.co/contact" target="_blank">
          Contact us
        </a>
      </div>
      <div className="footer-copyright">
        <p>@ 2024 Mentem by UNSW. All rights reserved.</p>
      </div>
    </div>
  )
}
